
<template>
	<Menu actual="/material-didactico" class="mt-6" />

	<div class="bg-white overflow-hidden">
		<div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
			<div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
			<div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
				<div>
					<h2 class="text-base text-morado font-semibold tracking-wide uppercase">Material didáctico</h2>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-vino sm:text-4xl">Apps</h3>
				</div>
			</div>
			<div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
				<div class="relative lg:row-start-1 lg:col-start-2">
					<svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
						<defs>
							<pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
								<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
							</pattern>
						</defs>
						<rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
					</svg>
					<div class="relative text-base mx-auto max-w-prose lg:max-w-none">
						<figure>
							<div class="aspect-w-12 aspect-h-7 lg:aspect-none">
								<img class="rounded-lg shadow-lg object-cover object-center" :src="require('@/assets/material-didactico/child-paint.jpg')" alt="Yo me quiero, yo me cuido" />
							</div>
						</figure>
					</div>
				</div>
				<div class="mt-8 lg:mt-0">
					<div class="text-base max-w-prose mx-auto lg:max-w-none">
						<p class="text-lg text-gray-500">Excelente herramienta para hablar de sexualidad con niñas y niños, para promover una educación sexual oportuna e integral, acorde a las diferentes edades.</p>
					</div>
					<div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
						<p>Proponemos  una estrategia pedagógica diferente,  para hablar con niñas y niños sobre sexualidad (6 a 12 años) y prevención de abuso sexual (5 a 8 años).</p>

						<p>El programa es digital,  para ir de acuerdo con la época y está diseñado  para  que el aprendizaje sea dinámico , ágil y divertido, creando  un ambiente de confianza , que abra la comunicación entre los infantes y los adultos.</p>


						<p>Reciben la información necesaria sobre el funcionamiento del cuerpo, a la vez que se promueven factores como autoestima, valores, equidad, etc. que influyen en la construcción de su personalidad.</p>

						<p>El material consta de una serie de libros que abordan el tema de acuerdo a las diferentes edades, cuidando que la información cumpla con los intereses de los menores y utilizando un lenguaje sencillo y apropiado para su edad.</p>

						<p>Cada libro( descargable en cualquier dispositivo), está acompañado de una serie de juegos interactivos, (rompecabezas, memoria secuencias, etc.), con el propósito de reforzar conocimientos  de manera más lúdica;  despertar el interés, y entusiasmo por aprender y participar. Acabar con el paradigma de ser un tema inconveniente y tengan la seguridad de que todas sus inquietudes son normales y los adultos estamos dispuestos a resolver.</p>

						<div class="text-verde font-bold text-3xl mt-12">Objetivos</div>
						<ul>
							<li>Transmitir los conocimientos necesarios sobre el cuerpo y sus funciones de acuerdo a su edad.</li>
							<li>Propiciar elementos formativos para facilitar y promover la conciencia y el aprecio de la sexualidad.</li>
							<li>Integrar en la mente de las niñas y los niños una percepción positiva hacia la sexualidad.</li>
							<li>Que niñas y niños se conviertan en adultos sexualmente sanos, responsables y felices.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<Materials />

	<div class="pt-4 pb-8 xl:pt-4 xl:pb-12  px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
		<div class="max-w-max lg:max-w-7xl mx-auto">
			<div class="relative z-10 mb-8 md:mb-2 md:px-6">
				<div class="text-base max-w-prose lg:max-w-none">
				<p class="mt-2 text-2xl leading-8 font-bold tracking-tight text-azul sm:text-3xl">Formato</p>
				</div>
			</div>

			<div class="relative">
				<div class="relative md:bg-white md:p-6">
					<div class="lg:grid lg:grid-cols-2 lg:gap-6">
						<div class="prose prose-indigo text-gray-500 lg:max-w-none space-y-4">
							<p>Serie de 5 libros (6 a 12 años),  de educación sexual infantil y un libro (5 a 8 años) para prevenir el abuso sexual.</p>
							<p>Son un instrumento de comunicación, brindan un método claro de qué, cuándo y cómo abordar el tema con las niñas y los niños.</p>
							<p>Se selecciona el libro de acuerdo a la edad del niño o la niña  para que coincida con sus dudas e intereses, por el cuerpo su funcionamiento y desarrollo.</p>
							<p>Están elaborados con un lenguaje sencillo para que los menores puedan leerlo por si mismos, o junto con el educador, y sirva como punto de partida para hablar sobre el tema: qué aprendiste, Qué sabías, resolviste dudas, ya te habían hablado del tema, quién te lo dijo, etcétera.</p>
							<p>Posterior al trabajo con el libro,  niñas y niños tienen acceso a una serie de juegos interactivos,  con los que podrán reforzar conocimientos y tener  una valiosa experiencia de aprendizaje.</p>
							<p>Al tratarse de un programa soportado con juegos, provocamos que los infantes deseen repetir  las actividades para dominar los retos que cada uno representa, lo que los ayuda a la interiorización de los conceptos.</p>
						</div>
						<div class="mt-6 prose prose-indigo text-gray-500 lg:mt-0 space-y-4">
							<p>Las instrucciones de cada juego se encuentran escritas o en forma oral, al igual que las conclusiones,  que consisten en un mensaje poderoso de lo que niñas y niños deben recordar, todo esto con objeto de reforzar el aprendizaje a través de  dos tipos de estímulos visual y de oído.</p>
							<p>Durante el juego reciben retroalimentación sobre si es correcta o incorrecta su respuesta con un semáforo verde o rojo respectivamente, lo que  promueve la autoevaluación  y se invita a niñas y niños a repetirlo hasta responder correctamente.</p>
							<p>Al final de cada serie de juegos hay un premio de figuras de papel recortables, y coleccionables para que tengan una sensación de logro.</p>
							<p>El acceso al programa y materiales  es por dos vías, por  sus características  de poderse manejar en grupo para escuelas e Instituciones  o individualmente, para padres de familia. Te invitamos a conocerlo.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
import { MailIcon, PhoneIcon } from '@heroicons/vue/solid'
import Menu from '@/components/core/Menu'
import Materials from '@/components/home/Materials'

export default {
	components: {
		MailIcon,
		PhoneIcon,
		Menu,
		Materials
	},
}
</script>